<template>
  <div class="dashboard-page">
    <div class="row align-items-stretch">
      <div class="col-7">
        <Pane small>
          <Payout :data="payoutData" />
        </Pane>
      </div>
      <div class="col-5 pl-0">
        <Pane small>
          <CpmAndBids :data="cpmAndBidsData" />
        </Pane>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Pane small>
          <Loader :visible="isLoading" />
          <DataGrid
            :data="statData"
            :model="incomeModel"
            :loading="isLoading"
            :detailedRequestConfig="detailedRequestConfig"
            v-on:update:col="updateColHandler($event)"
          />
        </Pane>
      </div>
    </div>
  </div>
</template>

<script>
import DataGrid from '@/components/decorative/DataGrid.vue';
import Loader from '@/components/decorative/Loader.vue';
import Pane from '@/components/decorative/Pane.vue';
// dashboards
import CpmAndBids from '@/components/dashboards/CpmAndBids.vue';
import Payout from '@/components/dashboards/Payout.vue';
// configs
import dashboardConfig from '@/configs/dashboardConfig';
import incomeModel from '@/configs/table-models/stats';
import detailedModels from '@/configs/table-models/statsDetailed';

export default {
  name: 'dashboard',
  mixins: [incomeModel, detailedModels],
  components: {
    Pane,
    CpmAndBids,
    Payout,
    DataGrid,
    Loader,
  },
  data() {
    return {
      ...dashboardConfig,
      statsConfig: {
        group: null,
        limit: 5,
      },
      isLoading: false,
    };
  },
  computed: {
    statData() {
      return Array.isArray(this.$store.getters.getDashboardStatistics)
        ? this.$store.getters.getDashboardStatistics.slice(0, -1)
        : [];
    },
    payoutData() {
      return this.$store.getters.getDashboardFinance;
    },
    cpmAndBidsData() {
      return {
        cpm: this.$store.getters.getDashboardEarningsCpm,
        bids: this.$store.getters.getDashboardEarningsBids,
      };
    },
    detailedRequestConfig() {
      const { start, end, group } = {
        ...this.selectedRange.value,
        ...this.statsConfig,
      };
      return {
        url: 'statistics/',
        start,
        end,
        group,
        detailedModels: this.detailedModels,
      };
    },
  },
  created() {
    if (
      !this.statData.length
      || this.$store.getters.getDashboardStatisticsGroup
        !== this.incomeModel.complex.options[0].value.group
    ) {
      this.statsConfig.group = this.incomeModel.complex.options[0].value.group;
      this.loadStat();
    }

    this.loadDashboards();
  },
  methods: {
    updateColHandler(event) {
      this.statsConfig.group = event.value.group || event.value.field;
      this.loadStat();
    },
    loadStat() {
      const {
        start, end, group, limit,
      } = {
        ...this.selectedRange.value,
        ...this.statsConfig,
      };
      this.isLoading = true;
      this.$store
        .dispatch('statsRequest', {
          dashboardStat: true,
          queryParams: {
            start,
            end,
            group,
            limit,
          },
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadDashboards() {
      this.$store.dispatch('getDashboards', dashboardConfig);
    },
  },
};
</script>

<style lang="scss">
.dashboard-page {
  > .row + .row {
    margin-top: 15px;
  }
}
</style>
