import axios from 'axios';
import apiUrl from '@/utils/apiUrl';

const initialState = () => ({
  dashboardStatistics: [],
  earnings: [],
  earningsChart: [],
  earningsChartMetric: null,
  earningsGroup: null,
  payouts: [],
  selectedRange: null,
  financesSelectedRange: null,
  statistics: [],
  statisticsChart: [],
  statisticsChartMetric: null,
  statisticsGroup: null,
  dashboardStatisticsGroup: null,
  subIds: [],
});

const statsModule = {
  state: initialState(),
  getters: {
    getDashboardStatistics: state => state.dashboardStatistics,
    getEarnings: state => state.earnings,
    getEarningsChart: state => state.earningsChart,
    getEarningsChartMetric: state => state.earningsChartMetric,
    getEarningsGroup: state => state.earningsGroup,
    getPayouts: state => state.payouts,
    getSelectedRange: state => state.selectedRange,
    getFinancesSelectedRange: state => state.financesSelectedRange,
    getStatistics: state => state.statistics,
    getStatisticsChart: state => state.statisticsChart,
    getStatisticsChartMetric: state => state.statisticsChartMetric,
    getStatisticsGroup: state => state.statisticsGroup,
    getDashboardStatisticsGroup: state => state.dashboardStatisticsGroup,
    getSubIds: state => state.subIds,
  },
  mutations: {
    set(state, { key, data }) {
      state[key] = data;
    },
    resetStatsState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async statsRequest({ commit }, payload) {
      return axios({
        url: apiUrl('statistics/', payload.queryParams),
        method: 'GET',
      })
        .then(({ data }) => {
          commit('set', {
            key: payload.dashboardStat ? 'dashboardStatistics' : 'statistics',
            data,
          });
          commit('set', {
            key: payload.dashboardStat
              ? 'dashboardStatisticsGroup'
              : 'statisticsGroup',
            data: payload.queryParams.group,
          });

          return data;
        })
        .catch(({ data }) => {
          throw data;
        });
    },
    async statsChartRequest({ commit }, payload) {
      return axios({
        url: apiUrl('statistics/chart/', payload.queryParams),
        method: 'GET',
      })
        .then(({ data }) => {
          commit('set', { key: 'statisticsChart', data });
          commit('set', {
            key: 'statisticsChartMetric',
            data: payload.queryParams.metric,
          });
          return data;
        })
        .catch(({ data }) => {
          throw data;
        });
    },
    async statExport(context, payload) {
      return axios({
        url: apiUrl('statistics/export/', payload.queryParams),
        method: 'GET',
        responseType: 'blob',
      }).then((result) => {
        const { data, request } = result;
        const urlArray = request.responseURL.split('/');
        const fileName = urlArray[urlArray.length - 1];

        const link = document.createElement('a');
        link.download = fileName;

        link.href = URL.createObjectURL(data);

        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    async payoutsRequest({ commit }) {
      return axios({
        url: apiUrl('finance/'),
        method: 'GET',
      })
        .then(({ data }) => {
          commit('set', { key: 'payouts', data });
          return data;
        })
        .catch(({ data }) => {
          throw data;
        });
    },
    async earningsRequest({ commit }, payload) {
      return axios({
        url: apiUrl('earnings/', payload.queryParams),
        method: 'GET',
      })
        .then(({ data }) => {
          commit('set', { key: 'earnings', data });
          commit('set', {
            key: 'earningsGroup',
            data: payload.queryParams.group,
          });
          return data;
        })
        .catch(({ data }) => {
          throw data;
        });
    },
    async earningsChartRequest({ commit }, payload) {
      return axios({
        url: apiUrl('earnings/chart/', payload.queryParams),
        method: 'GET',
      }).then(({ data }) => {
        commit('set', { key: 'earningsChart', data });
        commit('set', {
          key: 'earningsChartMetric',
          data: payload.queryParams.metric,
        });
        return data;
      });
    },
    async earningsExport(context, payload) {
      return axios({
        url: apiUrl('earnings/export/', payload.queryParams),
        method: 'GET',
        responseType: 'blob',
      }).then((result) => {
        const { data, request } = result;
        const urlArray = request.responseURL.split('/');
        const fileName = urlArray[urlArray.length - 1];

        const link = document.createElement('a');
        link.download = fileName;

        link.href = URL.createObjectURL(data);

        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    async subIdsRequest({ commit }) {
      return axios({
        url: apiUrl('host/subids/'),
        method: 'GET',
      }).then(({ data }) => {
        commit('set', { key: 'subIds', data });
        return data;
      });
    },
    setSelectedRange({ commit }, payload) {
      commit('set', { key: 'selectedRange', data: payload });
    },
    setFinancesSelectedRange({ commit }, payload) {
      commit('set', { key: 'financesSelectedRange', data: payload });
    },
  },
};

export default statsModule;
