import axios from 'axios';
import apiUrl from '@/utils/apiUrl';

function capitalize(word) {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
}

const initialState = () => ({
  dashboardFinance: null,
  dashboardEarningsBids: null,
  dashboardEarningsCpm: null,
});

const dashboardModule = {
  state: initialState(),
  getters: {
    getDashboardFinance: state => state.dashboardFinance,
    getDashboardEarningsBids: state => state.dashboardEarningsBids,
    getDashboardEarningsCpm: state => state.dashboardEarningsCpm,
  },
  mutations: {
    set(state, { key, data }) {
      state[key] = data;
    },
    resetDashboardState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async dashboardRequest({ commit }, { kind, stateKey, queryParams }) {
      return axios({
        url: apiUrl(`dashboard/${kind}/`, queryParams),
        method: 'GET',
      })
        .then(({ data }) => {
          commit('set', { key: stateKey, data });
          return data;
        })
        .catch((err) => {
          throw err.response;
        });
    },

    async getDashboards({ dispatch }, payload) {
      const {
        dashboardEarningsConfig,
        dashboardFinanceConfig,
      } = payload;

      const promises = [];

      const earnings = dashboardEarningsConfig.reduce(
        (acc, val) => [
          ...acc,
          dispatch('dashboardRequest', {
            kind: 'earnings',
            stateKey: `dashboardEarnings${capitalize(val.metric)}`,
            queryParams: val,
          }),
        ],
        [],
      );

      earnings.forEach(p => promises.push(p));

      const finance = dispatch('dashboardRequest', {
        kind: 'finance',
        stateKey: 'dashboardFinance',
        queryParams: dashboardFinanceConfig,
      });

      promises.push(finance);

      return Promise.all(promises);
    },
  },
};

export default dashboardModule;
