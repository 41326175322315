import Vue from 'vue';
import VueI18n from 'vue-i18n';

import enLocale from 'element-ui/lib/locale/lang/en';
import ruLocale from 'element-ui/lib/locale/lang/ru-RU';
import ElementLocale from 'element-ui/lib/locale';

import ru from './messages/ru.json';
import en from './messages/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'ru',
  messages: {
    en: {
      ...en,
      ...enLocale,
    },
    ru: {
      ...ru,
      ...ruLocale,
    },
  },
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;
